import React, {useState} from 'react';
import {connect} from 'react-redux';
import { Container, H1 } from './ListElements';
import ContactDetail from './ContactDetail';


const ContactList = ({contacts}) => {


    
    
    return(
        <>
            <Container>
                <H1>Admin::</H1>
                <br/>
                <ul>
                    
                { contacts.map(contact => < ContactDetail contact={contact} /> )}
                </ul>
             </Container>
        </>
    )
}

const mapStateToProps = state =>{
    return { contacts: state.contact_requests }}

export default connect(mapStateToProps)(ContactList);
