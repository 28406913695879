import React from 'react';
import Navbar from '../components/Navbar'
import Sidebar from '../components/Sidebar';
import ContactForm from '../components/Contact';
import Footer from '../components/Footer';

const ContactPage = () => {
    

    return (
        <>
            <Navbar/>
            <Sidebar />
            <ContactForm/>
            <Footer />
        </>
    );
};

export default ContactPage;
