import React, {useState} from 'react'
import jsChatVideo from '../../videos/JsChat.mp4'

// import  Video from '../../videos/video.mp4';

import {Button} from '../ButtonElements';
import { ExampleContainer, ExampleBackGround, VideoBackground, ExampleContent, ExampleHeader, ExampleP } from './JsChatElements';

const JsChat = ({id, video, header, paragraph, btn}) => {

    const [hover, setHover] = useState(false);

    const onHover = () =>{
        setHover(!hover);
    }
    return (
        <ExampleContainer id={id}>
            <ExampleBackGround>
                <VideoBackground autoPlay loop muted src={jsChatVideo} type='video/mp4' />
            </ExampleBackGround>
                <ExampleContent>
                    <ExampleHeader>{header}</ExampleHeader>
                    <ExampleP>{paragraph}</ExampleP>
                    <br/>
                    <Button to={btn} target='_blank' onMouseEnter={onHover} onMouseLeave={onHover}>Contact Me</Button>
                </ExampleContent>
        </ExampleContainer>
    )
};

export default JsChat
