import React from 'react';  // <-- Add this line
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Home from './pages';
import ContactPage from './pages/contact';
import AdminDashBoardPage from './pages/adminDashboard';
import JsChatPage from './pages/jsChat';
import RailsProjectPage from './pages/railsProject';

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/contact' element={<ContactPage />} />
        <Route path='/admin' element={<AdminDashBoardPage />} />
        <Route path='/jschat' element={<JsChatPage />} />
        <Route path='/railsproject' element={<RailsProjectPage />} />
      </Routes>
    </Router>
  );
}

export default App;
