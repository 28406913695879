import React from 'react';
import Navbar from '../components/Navbar'
import Sidebar from '../components/Sidebar';
import JsChat from '../components/JsChat';



import Footer from '../components/Footer';

const JsChatPage= () => {
    

    return (
        <>
            <Navbar/>
            <Sidebar />
            <JsChat/>
            <Footer />
        </>
    );
};

export default JsChatPage;
