import styled from 'styled-components';
import {Link} from 'react-router-dom';



export const FooterContainer = styled.footer
`
background-color: #010101;
corner-radius: 2rem;
`

export const FooterWrapper = styled.div
`
padding: 48px 24px;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
max-width: 1100px;
margin: 0 auto;

`

export const LinkContainer = styled.div
`
display: flex;
justify-content: center;

@media screen and (max-width: 820px) {
    padding-top: 32px;
}
`

export const LinkWrapper = styled.div
`
display: flex;

@media screen and (max-width: 820px) {
    flex-direction: row;
}
`

export const LinkItems = styled.div
`
display: flex;
flex-direction: row;
align-items: flex-start;
margin: 16px;
text-align: left;
width: 160px;
box-sizing: border-box;
color: #fff;

@media screen and (max-width: 420px){
    margin: 0;
    padding: 10px;
    width: 100%;
}
`

export const LinkTitle = styled.h1  
`
font-size: 14px;
margin-bottom: 16px;
`

export const FooterLink = styled(Link)
`
color: #fff;
text-decoration: none;
margin-bottom: 0.5rem;
font-size: 14px

&:hover {
    color: #01bf71;
    transition: 0.3s ease-in-out;
}
`

export const SocialMedia = styled.section
`
max-width: 1000px;
width: 100%;
`;

export const SocialMediaWrapper = styled.div
`
display: flex; 
justify-content: space-between;
align-items: center;
max-width: 1100px;
margin: 40px auto 0 auto;

@media screen and (max-width: 820px){
    flex-direction: column;
}
`

export const SocialLogo = styled(Link)
`
color: #fff;
justify-self: start;
cursor: pointer;
text-decoration: none;
font-size: 1.5rem;
display: flex;
align-items: center;
margin-bottom: 16px;
font-weight: bold;
font-family: 'Megrim';
`

export const SiteRights = styled.small
`
color: #fff;
margin-bottom: 16px;
`

// export const SocialLogo = styled.div
// `
// display: flex;
// justify-content: space-between;
// align-items: center;
// width: 240px
// `

export const SocialLinkIcon = styled.a
`
color: #fff;
font-size: 24px;
`