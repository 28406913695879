import React from 'react'

const ContactDetail = ({contact}) => {
   

   
   

    return (
        <div>
             <li key={contact.id}>
                     Name::{contact.name} Email::{contact.email} Phone::{contact.phone}
                <br/>
                Info::{contact.message}
                </li>
                
                
              
        </div>
    )
}

export default ContactDetail
